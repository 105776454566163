'use strict';

angular.module('informaApp')
    .directive('infGanttChartProfile',
        function ($timeout, NavigatorService, GanttChartStore, HashService, ModalHelper, ChartService, GanttChartMapper, GanttChartDataFilter) {
            return {
                restrict: 'E',
                templateUrl: 'directives/inf-gantt-chart-profile/template.ptl.html',
                scope: {
                    data: "=",
                    onEmpty: "=",
                    modalId: "=",
                    startDate: '=',
                    endDate: '=',
                    filter: '=',
                    getItemsToCompare: '=',
                    isDurationChart: '=',
                    getSearchData: '=',
                    onDataChanges: '='
                },
                getTabs: function () {
                    return GanttChartStore.getAll();
                },
                loadSource: function (scope) {
                    if (GanttChartStore.count() > 0) {
                        var source = scope.activeSourceId == null
                            ? GanttChartStore.getAll()[0]
                            : GanttChartStore.getSourceById(scope.activeSourceId);

                        scope.activeSourceId = source.id;
                        GanttChartStore.setActiveSourceId(scope.activeSourceId)

                        this.setSourceData(scope, source);
                    }
                },
                setSourceData: function (scope, source) {
                    scope.id = source.id;
                    scope.name = source.name;
                    scope.hash = source.hash;
                    scope.chartSource = source.chartSource;
                    scope.chartHorizontalbarSource = source.chartHorizontalbarSource;
                    scope.isDurationChart = source.isDurationChart;
                    scope.hasInvalidData = source.hasInvalidData;
                    scope.legendFilter = source.legendFilter;
                    scope.drugIndicationsIds = source.drugIndicationsIds;
                    scope.filter = source.filter;

                    scope.onDataChanges({
                        drugIndicationIds: scope.drugIndicationsIds,
                        id: scope.id,
                        name: scope.name
                    });
                },
                getSourceFromServer: function (scope, drugIndicationsIds) {
                    var _this = this;

                    var modal = "#" + scope.modalId;

                    ModalHelper.showLoadingModal(modal, function () {
                        scope.loading = true;

                        ChartService.ganttChart(drugIndicationsIds, scope.startDate, scope.endDate).then(function (response) {
                            var data = GanttChartMapper.mapAll(response.data.data),
                                dataForHorizontal = GanttChartMapper.mapAllForHorizontalbar(response.data.data);

                            var hasInvalidData = dataForHorizontal.some(GanttChartDataFilter.hasInvalidData);
                            var legendFilter = _this.getLegendFilter();

                            _this.saveTab(scope, scope.data.id, scope.data.name, scope.hash, data, dataForHorizontal, scope.isDurationChart, hasInvalidData, legendFilter, drugIndicationsIds, scope.filter);
                            scope.tabs = _this.getTabs();

                            _this.setSourceData(scope, {
                                id: scope.data.id,
                                name: scope.data.name,
                                hash: scope.hash,
                                chartSource: data,
                                chartHorizontalbarSource: dataForHorizontal,
                                isDurationChart: scope.isDurationChart,
                                hasInvalidData: hasInvalidData,
                                legendFilter: legendFilter,
                                drugIndicationsIds: drugIndicationsIds,
                                filter: scope.filter
                            });

                            scope.renderingComplete();
                        });
                    });
                },
                saveTab: function (scope, id, name, hash, chartSource, dataForHorizontal, isDurationChart, hasInvalidData, legendFilter, drugIndicationsIds, filter) {
                    GanttChartStore.saveSource({
                        id: id,
                        name: name,
                        hash: hash,
                        chartSource: chartSource,
                        chartHorizontalbarSource: dataForHorizontal,
                        isDurationChart: isDurationChart,
                        hasInvalidData: hasInvalidData,
                        legendFilter: legendFilter,
                        drugIndicationsIds: drugIndicationsIds,
                        filter
                    });

                    scope.activeSourceId = id;
                    GanttChartStore.setActiveSourceId(scope.activeSourceId);
                },
                closeTab: function (scope, id) {
                    GanttChartStore.removeSource(id);

                    if (GanttChartStore.count() > 0) {
                        scope.activeSourceId = 0;
                    } else {
                        if (scope.onEmpty) {
                            scope.onEmpty();
                        }
                    }
                },
                getLegendFilter: function () {
                    return {
                        phase1: true,
                        phase2: true,
                        phase3: true,
                        ndabla: true,
                        noData: true,
                        succeeded: true,
                        inProgress: true,
                        failed: true
                    };
                },
                exportChart: function (scope) {
                    const searchData = scope.getSearchData();
                    
                    if (scope.isDurationChart) {
                        return ChartService.exportDurationChart(searchData);
                    }

                    return ChartService.exportGanttChart(searchData);
                },
                link: function (scope, element, attr) {
                    scope.hiddenItems = {noData: true};

                    scope.$watch('hasInvalidData', function (newValue) {
                        scope.hiddenItems = {noData: !newValue};
                    });

                    var countTabs = GanttChartStore.count();
                    scope.isDurationChart = !!scope.isDurationChart;

                    if (!scope.data || !scope.data.drugIndicationsIds || !scope.data.drugIndicationsIds.shown) {

                        if (countTabs === 0) {
                            NavigatorService.openTable();
                            return;
                        }
                    }

                    scope.activeSourceId = GanttChartStore.getActiveSourceId();

                    scope.loading = true;

                    var _this = this;

                    scope.tabs = this.getTabs();

                    scope.$watch("data", function (newValue) {
                        var dataIsNotNull = newValue != null;

                        if (dataIsNotNull) {
                            scope.hash = HashService.getHashFromIntArray(newValue.drugIndicationsIds.shown);
                            var id = GanttChartStore.getIdByHash(scope.hash);

                            if (id != null) {
                                scope.activeSourceId = id;
                                _this.loadSource(scope);
                                scope.loading = false;
                            } else {
                                _this.getSourceFromServer(scope, newValue.drugIndicationsIds.shown);
                            }
                        } else {
                            _this.loadSource(scope);
                            scope.loading = false;
                        }

                    });

                    scope.closeTab = function (id) {
                        _this.closeTab(scope, id);

                        scope.activeSourceId = null;
                        _this.loadSource(scope);

                        scope.tabs = _this.getTabs();
                    };

                    scope.changeTab = function (id) {
                        scope.activeSourceId = id;

                        $timeout(function () {
                            _this.loadSource(scope);
                        })
                    };

                    scope.renderingComplete = function () {
                        scope.loading = false;
                        ModalHelper.hideModal("#" + scope.modalId)
                    };

                    scope.$watch("isDurationChart", function (newData) {
                        if (scope.hash != null && scope.id != null) {
                            _this.saveTab(scope, scope.id, scope.name, scope.hash, scope.chartSource, scope.chartHorizontalbarSource, newData, scope.hasInvalidData, scope.legendFilter, scope.drugIndicationsIds, scope.filter);
                        }
                    }, true);

                    scope.exportChart = function (sourceId, isDurationChart) {
                        _this.exportChart(scope)
                    }
                }
            }
        });
